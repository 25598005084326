import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "404: Not found",
  "includeToc": false,
  "tags": ["No page found"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const Box = makeShortcode("Box");
const Rig404Img = makeShortcode("Rig404Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Container maxWidth={false} mdxType="Container">
  <Container maxWidth="lg" mdxType="Container">
    <Box m={0} p={'1.25em 1.875em'} textAlign="center" mdxType="Box">
         <h1>Page Not Found.</h1>
         <p>You might be using an incorrect or old URL.</p>
           <p>Check your URL for any capitalization - our URLs are all lower-case.</p>
      <Rig404Img mdxType="Rig404Img" />
   <p>If you're not able to find what you are looking for, email <a href="mailto:nrrd@onrr.gov">nrrd@onrr.gov</a> with the incorrect url. We'll direct you to the information you need.</p>
     <p><a title="" href="/" target="_self">Go to homepage</a></p>
    </Box>
  </Container>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      